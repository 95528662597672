import React from 'react'
import './toolTileGrid.sass'

const ToolTileGrid = ({ children, className }) => (
  <div className={`tool-tile-grid${className ? ` ${className}` : ''}`}>
    {children}
  </div>
)

export default ToolTileGrid
