import React from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import './toolTile.sass'

import Button from '../button/button.js'
import Tag from '../tag/tag.js'
import BlurUp from '../blurUp/blurUp'

import ClockIcon from '../../assets/images/time.inline.svg'
import ToolIcon from '../../assets/images/team.inline.svg'

class ToolTile extends React.Component {
  render() {
    if (this.props.loading)
      return (
        <div
          className={cx(
            'tool-tile',
            this.props.className,
            'tool-tile--loading'
          )}
        >
          <div className="tool-tile__icon" />
          <div className="tool-tile__text__stancil" />
        </div>
      )

    return (
      <Link
        className={cx('tool-tile', this.props.className)}
        onClick={this.props.onClick}
        tabIndex={this.props.tabIndex || undefined}
        to={this.props.link}
      >
        {this.props.iconUrl && (
          <BlurUp
            role="presentation"
            className="tool-tile__icon"
            src={this.props.iconUrl}
          />
        )}
        {this.props.free && (
          <Tag
            tabIndex={this.props.tabIndex || undefined}
            className="tool-tile__tag tool-tile__tag--free"
            appearance="green"
            nolink="true"
          >
            Free
          </Tag>
        )}
        <span className="tool-tile__text">
          <strong className="tool-tile__title h4">{this.props.title}</strong>
          <Tag
            className="tool-tile__tag tool-tile__tag--category"
            nolink="true"
            appearance="blue"
          >
            {this.props.category}
          </Tag>
        </span>
        {this.props.tags && this.props.tags.length ? (
          <div className="tool-tile__params">
            <div>
              <ToolIcon />
              {this.props.tags[1]}
            </div>
            <div>
              <ClockIcon />
              {this.props.tags[0]}
            </div>
          </div>
        ) : null}
        <p
          className="tool-tile__summary"
          dangerouslySetInnerHTML={{ __html: this.props.summary }}
        />
        <Button tabIndex="-1" className="tool-tile__button">
          See Method
        </Button>
      </Link>
    )
  }
}

ToolTile.propTypes = {
  loading: PropTypes.bool,
  iconUrl: PropTypes.string,
  free: PropTypes.bool,
  category: PropTypes.string,
  title: PropTypes.string,
  summary: PropTypes.string,
  link: PropTypes.string,
  tabIndex: PropTypes.number,
  onClick: PropTypes.func,
  participants: PropTypes.objectOf(PropTypes.number),
  duration: PropTypes.objectOf(PropTypes.number),
}

export default ToolTile
