import React from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import ChevronRightIcon from '../../assets/images/chevronRight.inline.svg'

import PropTypes from 'prop-types'

import './breadcrumbs.sass'

class Breadcrumbs extends React.Component {
  render() {
    return (
      <div>
        {/* {this.props.breadcrumbs && (
          <BreadcrumbJsonLd
            itemListElements={this.props.breadcrumbs.map((crumb, index) => ({
              position: index + 1,
              name: crumb.title,
              item: crumb.href,
            }))}
          />
        )} */}
        <nav
          className={cx('breadcrumbs', this.props.className)}
          aria-label="breadcrumbs"
        >
          <ol className="breadcrumbs__list">
            {this.props.breadcrumbs &&
              this.props.breadcrumbs.map((crumb, index) => {
                return (
                  <li
                    className="breadcrumbs__list-item"
                    key={`breadcrumb${index}`}
                  >
                    {index === this.props.breadcrumbs.length - 1 ? (
                      <span role="link" tabIndex="0" aria-current="page">
                        {crumb.title}
                      </span>
                    ) : (
                      <span>
                        <Link to={crumb.href}>{crumb.title}</Link>
                        <span className="breadcrumbs__chevron">
                          <ChevronRightIcon />
                        </span>
                      </span>
                    )}
                  </li>
                )
              })}
          </ol>
        </nav>
      </div>
    )
  }
}

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array,
  className: PropTypes.string,
}

Breadcrumbs.defaultProps = {
  breadcrumbs: [],
}

export default Breadcrumbs
