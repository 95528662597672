import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import DefaultLayout from '../layouts/defaultLayout/defaultLayout'
import Breadcrumbs from '../components/breadcrumbs/breadcrumbs'
import Section from '../components/section/section'
import SectionHeadline from '../components/section/sectionHeadline'
import ToolTile from '../components/toolTile/toolTile'
import ToolTileGrid from '../components/toolTile/toolTileGrid'
import { mapMethod } from '../utils/mapMethodQueryResults'
import './templates.sass'

const mockBreadcrumbs = [
  { title: 'UX Strategy Kit', href: '/' },
  { title: 'Workshop Bundles', href: '/' },
]

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const BundlesPage: React.FC<PageProps<
  Queries.BundlesPageQuery,
  Queries.BundlesPageQueryVariables
>> = ({ data }) => {
  if (!data.directus.workshop_bundles) {
    return null
  }

  const { description, headline, bundles } = data.directus.workshop_bundles

  return (
    <DefaultLayout
      className="page-category"
      hasBanner={false}
      seo={{ title: 'Workshop Bundles' }}
    >
      <Section className="breadcrumbs-section">
        <Breadcrumbs breadcrumbs={mockBreadcrumbs} />
      </Section>
      <Section className="featured-tools-section">
        <header className="section-header section-header--flex templates__main-header">
          <SectionHeadline className="section-header__headline">
            {headline}
          </SectionHeadline>
          {description && (
            <div
              className="section-header__subline"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </header>
      </Section>
      {bundles?.map(bundleItem => {
        if (!bundleItem?.bundle_id) {
          return null
        }

        const { description, methods, name } = bundleItem?.bundle_id

        if (!methods) {
          return null
        }

        return (
          <Section className="featured-tools-section" key={name}>
            <header className="section-header section-header--flex templates__header">
              <SectionHeadline className="section-header__headline">
                {name}
              </SectionHeadline>
              {description && (
                <div
                  className="section-header__subline"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}
            </header>
            <ToolTileGrid className="templates__grid">
              {methods.map(methodItem => {
                if (!methodItem?.method_id) {
                  return null
                }

                const method: Method = mapMethod(methodItem.method_id)

                return (
                  <ToolTile
                    category={method.categories[0]}
                    duration={method.duration}
                    iconUrl={method.thumb}
                    key={method.slug}
                    link={`/methods/${method.slug}/`}
                    participants={method.participants}
                    summary={method.headline}
                    tags={method.tags}
                    title={method.name}
                  />
                )
              })}
            </ToolTileGrid>
          </Section>
        )
      })}
    </DefaultLayout>
  )
}

export default BundlesPage

export const query = graphql`
  query BundlesPage {
    directus {
      workshop_bundles {
        headline
        description
        bundles {
          bundle_id {
            name
            description
            methods {
              method_id {
                ...methodFragment
              }
            }
          }
        }
      }
    }
  }
`
